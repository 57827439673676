import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: <h2 style={{ color: '#8A2BE2' }}>Use Cases</h2>,
      paragraph: 'Discover how our AI solutions can transform your business processes.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader 
              data={sectionHeader} 
              className="center-content" 
            />
            <div className="center-content" style={{ marginBottom: '48px', display: 'flex', justifyContent: 'center', gap: '24px' }}>
              <div style={{ flex: '1', maxWidth: '500px' }}>
                <Image
                  src={require('./../../assets/images/feature-ai-ask_questions.png')}
                  alt="Feature AI Ask Questions"
                  width={500}
                  height={142} 
                />
              </div>
              <div style={{ flex: '1', maxWidth: '500px' }}>
                <Image
                  src={require('./../../assets/images/feature-ai-doc_generation.png')}
                  alt="Feature AI Doc Generation"
                  width={500}
                  height={142} 
                />
              </div>
            </div>
            <div className={tilesClasses}>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      Business Development Lead Prioritization
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      AI-powered system that performs research and analyzes multiple data points to rank leads in real time.
                    </p>
                    <ul className="m-0 text-sm reveal-from-right list-style-none" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      <li>• Focus sales efforts on high-potential prospects</li>
                      <li>• Free up resources by automating lead analytics</li>
                      <li>• Increase conversion rates and ROI</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      Request For Proposal (RFP) Generation Tool
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      A dynamic, AI-supported platform that quickly compiles detailed RFPs from relevant information.
                    </p>
                    <ul className="m-0 text-sm reveal-from-right list-style-none" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      <li>• Cut down on repetitive proposal drafting tasks</li>
                      <li>• Improve accuracy and maintain a consistent brand voice</li>
                      <li>• Reduce time-to-market for critical client proposals</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      Health Insurance Appeal Automation
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      An AI-driven solution that generates healthcare insurance appeals from the appropriate documents.
                    </p>
                    <ul className="m-0 text-sm reveal-from-right list-style-none" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      <li>• Automate the gathering of patient and claim data</li>
                      <li>• Expedite the appeals process and minimize errors</li>
                      <li>• Streamline revenue cycle management, boosting recovery rates</li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;