import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HowWeWork extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="container-xs">
              <h2 className="mt-0 mb-16 text-center" style={{ color: '#8A2BE2' }}>How We Work</h2>
              <div className="how-we-work-steps">
                <div className="step reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="step-title">1. Discovery & Analysis</h4>
                  <p className="step-text">
                    We learn about your challenges, your current processes, and your data sources to find the most impactful areas for AI-driven automation.
                  </p>
                </div>
                <div className="step reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="step-title">2. Rapid Prototyping</h4>
                  <p className="step-text">
                    We develop a quick proof of concept, so you see benefits early. Testing hypotheses fast helps you pivot or double down on what works best.
                  </p>
                </div>
                <div className="step reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="step-title">3. Iterative Development</h4>
                  <p className="step-text">
                    We refine and expand the solution based on feedback, ensuring minimal risk and maximum return on investment.
                  </p>
                </div>
                <div className="step reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="step-title">4. Ongoing Support & Optimization</h4>
                  <p className="step-text">
                    Even after deployment, we help you monitor performance and adapt to changing market demands or new data sources, ensuring sustainable growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HowWeWork.propTypes = propTypes;
HowWeWork.defaultProps = defaultProps;

export default HowWeWork; 