import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
// import FeaturesTiles from '../components/sections/FeaturesTiles';
import Services from '../components/sections/Services';
import UsecasesTiles from '../components/sections/UsecasesTiles';
import HowWeWork from '../components/sections/HowWeWork';
import Contact from '../components/sections/Contact';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit imageFill className="illustration-section-01" />
        {/* <FeaturesTiles /> */}
        <UsecasesTiles topDivider bottomDivider />
        <Services />
        <HowWeWork topDivider />
        <Contact className="illustration-section-02" />
      </React.Fragment>
    );
  }
}

export default Home;