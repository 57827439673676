import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import logo from "../../assets/images/krovus-512.png";
import { Link as ScrollLink } from 'react-scroll';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
        'hero section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    return (
        <section
            {...props}
            className={outerClasses}
            id="features"
        >
          <div className="container">
            <div className={innerClasses}>
              <div className={splitClasses}>
                <div className="split-item">
                  <div className="hero-content split-item-content center-content-mobile">
                    <Image
                      src={logo}
                      alt="Krovus"
                      width={512}
                      height={512}
                    />
                  </div>
                  <div className="hero-content split-item-content center-content-mobile">
                    <h4 className="mt-0 mb-24 reveal-from-left">
                      AI-powered business workflow automation
                    </h4>
                    <p className="mt-0 mb-24 reveal-from-left" data-reveal-delay="200">
                      Focus on high impact tasks that require human judgment after we automate the repetitive and time-consuming elements of your business processes.
                    </p>
                    <div className="reveal-from-left" data-reveal-delay="400">
                      <Button 
                        tag="a" 
                        color="primary" 
                        className="shine-button"
                        href="https://calendar.app.google/BzqmBnRQ1gjuuww89"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{backgroundColor: '#000000', borderColor: '#000000'}}>
                        Schedule a Free Consultation
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="scroll-indicator">
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                  <div className="chevron"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;