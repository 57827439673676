import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Contact extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="container-xs">
              <h2 className="mt-0 mb-16" style={{ color: '#8A2BE2' }}>Contact Us</h2>
              <p className="m-0 mb-32">
                Ready to Speed Up Your Decisions and Transform Your Processes? Let's talk about how our AI-powered solutions can give you a competitive edge.
              </p>
              <div>
                <Button 
                  tag="a" 
                  color="primary" 
                  className="shine-button mb-24"
                  href="https://calendar.app.google/BzqmBnRQ1gjuuww89"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{backgroundColor: '#000000', borderColor: '#000000'}}>
                  Schedule a Free Consultation
                </Button>
              </div>
              <div>
                <span>Email: </span>
                <a href="mailto:info@krovus.com" className="text-color-primary">info@krovus.com</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact; 