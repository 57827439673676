import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Services extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className="container-xs">
              <h2 className="mt-0 mb-16 text-center" style={{ color: '#8A2BE2' }}>Services</h2>
              <div className="services-list">
                <div className="service-item reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="service-title">Automation Consulting</h4>
                  <p className="service-text">
                    We analyze your business processes, identify automation opportunities, and develop a tailored AI strategy to optimize your operations.
                  </p>
                </div>
                <div className="service-item reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="service-title">Product Development</h4>
                  <p className="service-text">
                    Our expert team builds custom AI-powered tools and applications to solve your unique business challenges and streamline your workflows.
                  </p>
                </div>
                <div className="service-item reveal-from-bottom" data-reveal-delay="100">
                  <h4 className="service-title">Workflow Automation</h4>
                  <p className="service-text">
                    From data analysis to decision-making, we automate critical processes to save you time and resources, allowing you to focus on what matters most.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Services.propTypes = propTypes;
Services.defaultProps = defaultProps;

export default Services; 